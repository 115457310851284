// Request
export interface CreateRequest {
  role: UserRole;
  name: string;
  email: string;
}

export interface UpdateRequest {
  name?: string;
  role?: UserRole;
}

export interface UpdateProfileRequest {
  profilePictureUrl?: string | null;
  email?: string;
  name?: string;
  phoneNumber?: string;
  birthdate?: string;
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  password: string;
}

// Response
export enum UserRole {
  USER = "USER",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export const UserRoleTranslated = {
  [UserRole.USER]: "Usuário",
  [UserRole.ADMINISTRATOR]: "Administrador",
};

export enum PermissionController {
  USERS = "USERS",
  CATALOG = "CATALOG",
}

type PermissionModule = "WEB" | "DASH";
type PermissionAccess = "READ" | "WRITE";

interface Permission {
  module: PermissionModule;
  controller: PermissionController;
  access: PermissionAccess;
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export const UserStatusTranslated = {
  [UserStatus.ACTIVE]: "Ativo",
  [UserStatus.BLOCKED]: "Bloqueado",
};

export interface User {
  id: string;
  role: UserRole;
  roleTranslated: string;
  permissions: Permission[] | null;
  status: UserStatus;
  statusTranslated: string;
  name: string;
  email: string;
  cpf: string;
  phoneNumber: string;
  birthdate: string;
  profilePictureUrl?: string | null;
}
