import { RouteRecordRaw } from "vue-router";

import { PermissionController } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/login" },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: {
      requiresAuth: true,
      permissionController: PermissionController.CATALOG,
    },
    children: [
      {
        path: "busca",
        name: "Busca de produtos",
        component: () => import("../views/admin/search/Search.vue"),
        meta: {
          requiresAuth: true,
          permissionController: PermissionController.CATALOG,
          priority: 1,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Dados pessoais", path: "/minha-conta" },
        { name: "Usuários do painel", path: "/usuarios" },
      ],
    },
    children: [
      {
        path: "minha-conta",
        name: "Minha conta",
        component: () => import("../views/admin/settings/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 4 },
      },
      {
        path: "usuarios/:id?",
        name: "Usuários",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/settings/users/UserForm.vue"),
          table: () => import("../views/admin/settings/users/UserTable.vue"),
        },
        meta: { requiresAuth: true, permissionController: PermissionController.USERS, priority: 4 },
      },
    ],
  },
];

export default routes;
